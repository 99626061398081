/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth}  from '../../../../../app/modules/auth/core/Auth'
import {permissions} from '../../../../../app/modules/apps/setting/role/table/columns/_columns'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {can} = useAuth()
  const reportPerms = permissions.filter(({section})=>section === 'report').map(({value})=>value)

  return (
    <>
      { can('view_dashboard') &&
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      }
      { can(reportPerms) &&
      <SidebarMenuItem
        to='/report'
        icon='element-11'
        title={"รายงาน"}
        fontIcon='bi-app-indicator'
      />
      }
      {/*<SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>*/}
      { can(['view_dashboard', 'view_report'])  &&
      <div className='menu-item'>
        <hr />
      </div>
      }
      {/*<SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>*/}
      { can(['view_treatment', 'edit_treatment', 'cancel_receipt'])  &&
      <SidebarMenuItem
        to='/treatment'
        icon='syringe'
        title='จัดการรักษา'
        fontIcon='bi-layers'
      />
     }
       { can(['view_patient', 'edit_patient'])  &&
      <SidebarMenuItem
        to='/patient'
        icon='people'
        title='คนไข้'
        fontIcon='bi-layers'
      />
      }
      { can(['view_schedule', 'edit_schedule'])  &&
      <SidebarMenuItem
        to='/schedule'
        icon='calendar'
        title='ตารางนัดหมาย'
        fontIcon='bi-layers'
      />
      }
      { can(['view_booking', 'edit_booking'])  &&
      <SidebarMenuItem
        to='/appointment/tomorrow'
        icon='calendar-edit'
        title='จัดการนัดหมาย'
        fontIcon='bi-layers'
      />
      }
    { can('edit_product')  &&
      <SidebarMenuItem
        to='/product-sales'
        icon='basket'
        title='ขายสินค้า'
        fontIcon='bi-layers'
      />
      }
      { can(['view_crm', 'edit_crm'])  &&
      <SidebarMenuItem
        to='/crm'
        icon='user-square'
        title='CRM'
        fontIcon='bi-layers'
      />
      }
      { can(['edit_product', 'view_booking', 'edit_booking', 'view_schedule', 'edit_schedule',
        'view_crm', 'edit_crm', 'view_patient', 'edit_patient', 'view_treatment',
        'edit_treatment', 'cancel_receipt'
        ]) &&
      <div className='menu-item'>
        <hr />
      </div>
      }
     { can(['view_doctor_schedule', 'edit_dotor_schedule'])  &&
      <SidebarMenuItem
        to='/doctor-schedule'
        icon='calendar'
        title='ตารางลงตรวจแพทย์'
        fontIcon='bi-layers'
      />
      }
      { can(['view_lab', 'edit_lab', 'view_lab_report'])  &&
      <SidebarMenuItem
        to='/lab'
        icon='pulse'
        title='ห้องแล็บ'
        fontIcon='bi-layers'
      />
      }
      { can('view_medical_form')  &&
      <SidebarMenuItem
        to='/form'
        icon='document'
        title='แบบฟอร์ม'
        fontIcon='bi-layers'
      />
      }
      { can('view_accounting')  &&
      <SidebarMenuItem
        to='/accounting'
        icon='wallet'
        title='บัญชี'
        fontIcon='bi-layers'
      />
      }
      { can(['view_doctor_schedule', 'edit_dotor_schedule', 'view_lab', 'edit_lab', 'view_lab_report',
      'view_medical_form', 'view_account'
     ])  &&
      <div className='menu-item'>
        <hr />
      </div>
      }
      { can('setting_', true)  &&
      <SidebarMenuItem
        to='/setting'
        icon='setting-2'
        title='การจัดการระบบ'
        fontIcon='bi-layers'
      />
      }
      {/*<div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>*/}
    </>
  )
}

export {SidebarMenuMain}
